export const useAnalytics = () => {
  const { $trackEvent } = useNuxtApp()

  function trackCourseEvent(event: string, step: number, course: FullCourse) {
    let lessonType: string | undefined
    const content: CourseContent | undefined = course.content[step]
    if (!content) {
      return
    }
    if (content.layout === "text-content") {
      lessonType = "case"
    } else if (content.layout === "file-content") {
      lessonType = "download"
    } else if (content.layout === "breach-content") {
      lessonType = "breaches"
    } else if (content.layout === "video-content") {
      lessonType = "video"
    }
    const eventProps: {
      parent_id: string
      lesson_id: string
      parent_type: string
      disable_route_tracking: boolean
      lesson_type?: string
    } = {
      parent_id: course.id,
      lesson_id: content.id,
      parent_type: "course",
      disable_route_tracking: true,
    }
    if (lessonType) {
      eventProps["lesson_type"] = lessonType
    }
    $trackEvent(event, eventProps)
  }
  return { trackEvent: $trackEvent, trackCourseEvent }
}
